import * as React from 'react';
import { Typography, Chip, Avatar } from '@material-ui/core';

import { dataTableClasses } from '../../components/DataTable/DataTableStyles';
import { ORDER_TYPE, COMMENT_TYPE } from '../../shared/constants';
import { InstallationCompleteOrder } from '../InstallationOrders/InstallationCompleteOrder';

import akemi from '../../assets/AKEMI_logo.png';

type RowDetail = { row: Order & Material & { conditionalDate: Date } }

export const CompletedOrdersTableRowMobile = ({ row: { actionType, files, plates, comments, id, reference_number, name, thickness, type, deliveryAddress, materialStatus, cutouts, history, need_impregnation, conditionalDate, pickup_date, installation_date, installation_confirmed } }: RowDetail) => {
  const classes = dataTableClasses();
  
  const renderElement = () => {
    if (!["installation", "completed"].includes(actionType)) {
      return;
    }
    
    const isInfoInstallation = !!comments.find(comment => comment.comment_type === COMMENT_TYPE.INFO);
    return (
      <InstallationCompleteOrder
        orderId={id}
        reference_number={reference_number}
        plates={plates!}
        files={files}
        isInfo={isInfoInstallation}
        pickup_date={pickup_date}
        conditionalDate={conditionalDate}
        installation_date={installation_date}
        installation_confirmed={installation_confirmed}
        />
      );
    };
    
  const craneRequired = history && history['Crane required'];
  const extraHelpForLift = history && history['Extra help required to lift'];
  const estimatedInstallationTime = history && history['Estimated Installation Time'];

  return (
    <div>
      <div className={classes.row}>
        <Typography variant="caption">Material</Typography>
        <Typography variant="body2">{name} {need_impregnation && (<img src={akemi} alt="AKEMI" width="45" height="12" />)}</Typography>
      </div>
      <div className={classes.row}>
        <Typography variant="caption">Thickness</Typography>
        <Typography variant="body2">{thickness}</Typography>
      </div>
      <div className={classes.row}>
        <Typography variant="caption">Surface</Typography>
        <Typography variant="body2">{type}</Typography>
      </div>
      <br/>
      <div className={classes.row}>
        <Typography variant="caption">Address</Typography>
        <Typography variant="body2">{deliveryAddress || '<missing information>'}</Typography>
      </div>
      <div className={classes.row}>
        <Typography variant="caption">Status material</Typography>
        <Typography variant="body2">{materialStatus || '<Not registered>'}</Typography>
      </div>
      <div className={classes.section}>
        {cutouts?.map((cutout: any) =>
          <Chip size="small"
            color={cutout.inStock ? "primary" : "secondary"}
            key={cutout.key}
            label={cutout.label}
            avatar={<Avatar>{cutout.count}</Avatar>}
            style={{ margin: '4px 0' }}
          />
        )}
      </div>
      {craneRequired && (
        <div className={classes.row}>
          <Typography variant="caption">Crane required:</Typography>
          <Typography variant="body2">{craneRequired}</Typography>
        </div>
      )}
      {extraHelpForLift && (
        <div className={classes.row}>
          <Typography variant="caption">Extra help for lift</Typography>
          <Typography variant="body2">{extraHelpForLift}</Typography>
        </div>
      )}
      {estimatedInstallationTime && (
        <div className={classes.row}>
          <Typography variant="caption">Estimated installation time</Typography>
          <Typography variant="body2">{estimatedInstallationTime}</Typography>
        </div>
      )}
      <div className={classes.button}>
        {renderElement()}
      </div>
    </div>
  );
};
