import * as React from 'react';
import { useDispatch } from 'react-redux';

import { MyDialog } from '../../components/Dialog/MyDialog';
import { useErrorHandler } from "../../hooks/useErrorHandler";

import { fetchCurrentFilesForRecord } from '../../redux/files/FileActions';

import { freightOrder } from '../../redux/actions/ordersActions';
import { FreightConfirmDateDialog } from './FreightConfirmDateDialog';

import { ORDER_TYPE, PLATE_TYPE } from '../../shared/constants';
import { FreightWizardQuestions } from "../../shared/WizardStep/FreightWizardQuestions";
import { WizardStep } from '../../components/WizardStep/WizardStep';
import { SPECIAL_COMPONENTS } from "../../shared/WizardStep/WizardEnums";

import { formatToDate } from '../../services/DateFormatter';

type FreightDialogType = {
  data: Order,
  plates: Plate[]
};

export function FreightDialog({ data, plates }: FreightDialogType) {
  const dispatch = useDispatch();
  const { handleError } = useErrorHandler();

  React.useEffect(() => {
    dispatch(fetchCurrentFilesForRecord(data.id, ORDER_TYPE.FREIGHT));
  }, []);

  const [userId, setUserId] = React.useState<number>();
  const [allStepsCompleted, setAllStepsCompleted] = React.useState(false);

  const onStepsCompleted = (value: boolean) => {
    setAllStepsCompleted(value);
  }

  const onSubmit = () => {
    if (allStepsCompleted && anyPlateChecked) {
      submitFreightOrder();
    }
  }

  const [platesChecked, setPlatesChecked] = React.useState({} as any);

  const onChangeCheckbox = (value: number) => setPlatesChecked({
    ...platesChecked,
    [value]: !platesChecked[value]
  });

  const getExtraData = (spec: SPECIAL_COMPONENTS) => {
    const plates_checked = Object.fromEntries(
      plates.map(plate => [plate.id, platesChecked[plate.id] || plate.has_freight])
    );
    const plates_disabled = Object.fromEntries(
      plates.map(plate => [plate.id, plate.has_freight || plate.current_stage !== PLATE_TYPE.INSTALLATION])
    );

    switch (spec) {
      case SPECIAL_COMPONENTS.PlatesCheckbox:
        return {
          plates,
          onChangeCheckbox,
          isChecked: plates_checked,
          isDisabled: plates_disabled
        }

      default:
        return {};
    }
  }

  const anyPlateChecked = plates.some(plate => platesChecked[plate.id])

  const specHasError = React.useMemo(() => ({
    [SPECIAL_COMPONENTS.PlatesCheckbox]: !anyPlateChecked
  }), [platesChecked]);

  async function submitFreightOrder() {
    try {
      const checkedArray = Object.keys(platesChecked);
      await freightOrder(data.id, {
        user_id: userId,
        plates: checkedArray.filter(key => platesChecked[key])
      });
    } catch (err) {
      // @ts-ignore
      handleError(err);
      throw err;
    }
  }

  const deliveryDate = new Date(data.delivery_date);
  const deliveryMethod = data.delivery_method || data.packingMethod;
  const staticDeliveryMethod = data.sellerTeams.staticPackingMethodKey;

  if (!data.pickup_date || !data.delivery_method) {
    const deliveryDate = new Date(data.delivery_date);
    const deliveryMethod = data.delivery_method || data.packingMethod;
    const staticDeliveryMethod = data.sellerTeams.staticPackingMethodKey;
    
    return (
      <FreightConfirmDateDialog
        orderId={data.id}
        deliveryDate={deliveryDate}
        deliveryMethod={deliveryMethod}
        staticDeliveryMethod={staticDeliveryMethod}
      />
    )
  }

  return (
    <MyDialog
      buttonLabel={formatToDate(data.pickup_date)}
      color="primary"
      customColor="greenBorder"
      dialogTitle={data.reference_number}
      onSubmit={onSubmit}
      onSubmitClose
      onSubmitDisabled={!allStepsCompleted || !anyPlateChecked}
      cancelButtonText="Close"
      modalSize="xl"
      minHeight={650}
      isStepper={true}
    >
      {({ onClose }: any) => (
        <WizardStep
          orderId={data.id}
          orderType={ORDER_TYPE.FREIGHT}
          stepList={FreightWizardQuestions}
          onStepsCompleted={onStepsCompleted}
          onUserIdChange={setUserId}
          onClose={onClose}
          getExtraData={getExtraData}
          specHasError={specHasError}
        />
      )}
    </MyDialog>
  );
}
