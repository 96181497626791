import * as React from "react";

import { MetaDataTableRowPlate } from "./MetaDataTableRowPlate";
import { CompletedOrdersTableRowMobile } from "../CompletedOrders/CompletedOrdersTableRowMobile";

export const getContentComponent = (params: any): any => {
  const { row: { actionType } } = params;
  switch (actionType) {
    case "prepare":
      return <MetaDataTableRowPlate {...params}/>;

    case "measurement":
    case "freight":
    case "internal_control":
    case "storage":
    case "installation":
    case "completed":
      return <CompletedOrdersTableRowMobile {...params}/>;

    case "plate":
      return <MetaDataTableRowPlate {...params}/>;

    default:
      return null;
  }
};